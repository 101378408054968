<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-tab
          :dense="true"
          type="pagetop"
          :tabItems.sync="tabItems"
          :inlineLabel="true"
          v-model="tab"
          align="left"
        >
          <template v-slot:default="tab">
            <component
              :is="tab.component"
            />
          </template>
        </c-tab>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'auth-group',
  data() {
    return {
      searchUrl: '',
      tab: 'codeMaster',
      tabItems: [
        { name: 'codeMaster', icon: 'list', label: '코드마스터 관리', component: () => import(`${'./codeMaster.vue'}`) },
        { name: 'codeGroup', icon: 'groups', label: '도메인 및 코드그룹 관리', component: () => import(`${'./codeGroup.vue'}`) },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
    },
  }
};
</script>
